<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Finances & Comptabilité</h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_sale"
          v-model="alert_sale"
          name="alert_sale"
          switch
          inline
        >
          <span>Alerte lors de l'achat</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_expense"
          v-model="alert_expense"
          switch
          inline
        >
          <span>Alerte lors de dépense</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_payment"
          v-model="alert_payment"
          :checked="alert_payment"
          switch
          inline
        >
          <span>Alerte lors de payement</span>
        </b-form-checkbox>
      </b-col>

      <!-- application switch -->
      <h6 class="section-label mx-1 mt-2">Gestion de Stock</h6>
      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox
          id="alert_non_attendance"
          v-model="alert_non_attendance"
          name="alert_non_attendance"
          switch
          inline
        >
          <span>Alert lors du stock minimum</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_loss"
          v-model="alert_loss"
          name="alert_loss"
          switch
          inline
        >
          <span>Alerte lors d'enregistrement de Perte</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_stock"
          v-model="alert_stock"
          name="alert_stock"
          switch
          inline
        >
          <span>Alert lors des mouvements de stock</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_expiration"
          v-model="alert_expiration"
          name="alert_expiration"
          switch
          inline
        >
          <span>Alert expiration des articles</span>
        </b-form-checkbox>
      </b-col>
      <!--/ application switch -->

      <!-- application switch -->
      <h6 class="section-label mx-1 mt-2">Utilisateurs [SMS/Email]</h6>
      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox
          id="alert_contact"
          v-model="alert_contact"
          name="alert_contact"
          switch
          inline
        >
          <span>Alerter Formulaire de Contact</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mt-1 mb-2">
        <b-form-checkbox
          id="alert_sms"
          v-model="alert_sms"
          name="alert_sms"
          switch
          inline
        >
          <span>Alerter Via SMS</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_email"
          v-model="alert_email"
          name="alert_email"
          switch
          inline
        >
          <span>Alerter Via Email</span>
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox
          id="alert_fake_account"
          v-model="alert_fake_account"
          name="alert_fake_account"
          switch
          inline
        >
          <span>Alerte lors du Mauvais Compte/Mot de passe</span>
        </b-form-checkbox>
      </b-col>
      <!--/ application switch -->

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="postCorporationSettingNotificationForm"
        >
          Enregistrer
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mt-1"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import { BButton, BRow, BCol, BCard, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { $themeConfig } from "@themeConfig";
import axios from "axios";
const userData = JSON.parse(localStorage.getItem("userData"));

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.notificationData)),
      sku_corporation: userData.sku_corporation,
      alert_sale: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_sale
      ),
      alert_expense: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_expense
      ),
      alert_payment: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_payment
      ),

      alert_non_attendance: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_non_attendance
      ),
      alert_loss: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_loss
      ),
      alert_stock: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_stock
      ),
      alert_expiration: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_expiration
      ),
      alert_sms: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_sms
      ),
      alert_email: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_email
      ),
      alert_contact: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_contact
      ),
      alert_fake_account: this.convertToBool(
        JSON.parse(JSON.stringify(this.notificationData)).alert_fake_account
      ),
      errors: [],
    };
  },
  methods: {
    convertToBool(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
    convertBoolToInt(value) {
      if (value == true) {
        return 1;
      } else {
        return 0;
      }
    },
    async postCorporationSettingNotificationForm() {
      // for the web app //
      let formData = new FormData();
      formData.append("sku_corporation", this.sku_corporation);
      formData.append("alert_sale", this.alert_sale);
      formData.append("alert_expense", this.alert_expense);
      formData.append("alert_payment", this.alert_payment);

      formData.append("alert_non_attendance", this.alert_non_attendance);
      formData.append("alert_loss", this.alert_loss);
      formData.append("alert_stock", this.alert_stock);
      formData.append("alert_expiration", this.alert_expiration);

      formData.append("alert_sms", this.alert_sms);
      formData.append("alert_email", this.alert_email);
      formData.append("alert_contact", this.alert_contact);
      formData.append("alert_fake_account", this.alert_fake_account);
      // 
      await myApi
        .post("update_corporation_infos", formData)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.localOptions = JSON.parse(JSON.stringify(this.informationData));
    },
  },
};
</script>
